import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MobileGuard } from './auth/mobile.guard';
import { AuthGuard } from './shared/guard/auth.guard';
import { ModuleGuard } from './shared/guard/module.guard';
import { exchangesResolver } from './shared/services/exchanges-resolver.service';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'info',
    loadChildren: () => import('./info/info.module').then(m => m.InfoModule)
  },
  {
    path: 'monitor',
    canActivate: [AuthGuard, MobileGuard],
    data: {
      role: ['monitor', 'operations', "CashManagement", 'logistics', 'treasury', 'inspector']
    },
    resolve: { exchanges: exchangesResolver },
    loadChildren: () => import('./monitor/monitor.module').then(m => m.MonitorModule)
  },
  {
    path: 'monitor-cash',
    canActivate: [AuthGuard, MobileGuard],
    data: {
      role: ['cashMonitor']
    },
    loadChildren: () => import('./monitor-cash/monitor-cash.module').then(m => m.MonitorCashModule)
  },
  {
    path: 'users',
    canActivate: [AuthGuard, MobileGuard],
    data: {
      role: ['admin']
    },
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
  },
  {
    path: 'customers',
    canActivate: [AuthGuard, MobileGuard],
    data: {
      role: ['admin', 'operations', 'management']
    },
    loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule)
  },
  {
    path: 'supplier',
    canActivate: [AuthGuard],
    data: {
      role: ['admin', 'productOperator', 'management']
    },
    loadChildren: () => import('./supplier/supplier.module').then(m => m.SupplierModule)
  },
  {
    path: 'mobile',
    canActivate: [AuthGuard],
    loadChildren: () => import('./mobile/mobile.module').then(m => m.MobileModule)
  },
  {
    path: 'set-new-password',
    loadChildren: () => import('./set-new-password/set-new-password.module').then(m => m.SetNewPasswordModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule)
  },
  {
    path: 'orders',
    canActivate: [AuthGuard, MobileGuard],
    data: {
      role: ['operations', 'logistics', 'treasury', 'inspector', "reception", "orderNotification", 'CashManagement']
    },
    loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule)
  },
  {
    path: 'delivery',
    canActivate: [AuthGuard, MobileGuard],
    data: {
      role: ['admin', 'management']
    },
    loadChildren: () => import('./delivery/delivery.module').then(m => m.DeliveryModule)
  },
  {
    path: 'order-report',
    canActivate: [AuthGuard, MobileGuard],
    data: {
      role: ['operations', 'logistics', 'treasury', 'inspector', "CashManagement"]
    },
    loadChildren: () => import('./orders/order-report/order-report.module').then(m => m.OrderReportModule)
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    data: {
      role: ['inspector']
    },
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'conciliation',
    canActivate: [AuthGuard],
    data: {
      role: ['operations', 'treasury', 'inspector', "CashManagement"]
    },
    loadChildren: () => import('./conciliation/conciliation.module').then(m => m.ConciliationModule)
  },
  {
    path: 'history-conciliation',
    canActivate: [AuthGuard],
    data: {
      role: ['operations', 'treasury', 'inspector', "CashManagement"]
    },
    loadChildren: () => import('./history-consolidate/history-consolidate.module').then(m => m.HistoryConsolidateModule)
  },
  {
    path: 'bug-report',
    canActivate: [AuthGuard],
    data: {
      role: ['monitor',
        'operations',
        'supervisor',
        'productOperator',
        'logistics',
        'treasury',
        'inspector',
        'reception',
        'orderNotification',
        'CashManagement']
    },
    loadChildren: () => import('./bug-report/bug-report.module').then(m => m.BugReportModule)
  },
  {
    path: 'products',
    canActivate: [AuthGuard, MobileGuard],
    data: {
      role: ['productOperator']
    },
    loadChildren: () => import('./products/products.module').then(m => m.ProductsModule)
  },
  {
    path: 'products-report',
    canActivate: [AuthGuard, MobileGuard],
    data: {
      role: ['productOperator']
    },
    loadChildren: () => import('./products/product-report/product-report.module').then(m => m.ProductReportModule)
  },
  {
    path: 'wallet',
    canActivate: [AuthGuard, MobileGuard],
    data: {
      role: ['balanceCustomer']
    },
    loadChildren: () => import('./wallet/wallet.module').then(m => m.WalletModule)
  },
  {
    path: 'service-payments',
    canActivate: [AuthGuard, MobileGuard],
    data: {
      role: ['customerPayments']
    },
    loadChildren: () => import('./service-payments/service-payments/service-payments.module').then(m => m.ServicePaymentsModule)
  },
  {
    path: 'service-managment',
    canActivate: [AuthGuard, MobileGuard],
    data: {
      role: ['productOperator']
    },
    loadChildren: () => import('./service-payments/service-managment/service-managment.module').then(m => m.ServiceManagmentModule)
  },
  {
    path: 'audit',
    canActivate: [AuthGuard],
    data: {
      role: ['admin']
    },
    loadChildren: () => import('./audit/audit.module').then(m => m.AuditModule)
  },
  {
    path: 'relase-notes',
    canActivate: [AuthGuard],
    loadChildren: () => import('./release-notes/release-notes.module').then(m => m.ReleaseNotesModule)
  },
  {
    path: 'accounts-managment',
    canActivate: [AuthGuard, ModuleGuard],
    data: {
      role: ['accountManagment', 'accountOperator'],
      module: ['account']
    },
    loadChildren: () => import('./account/accounts-managment/accounts-managment.module').then(m => m.AccountsManagmentModule)
  },
  {
    path: 'accounts-transfer',
    canActivate: [AuthGuard, ModuleGuard, MobileGuard],
    data: {
      role: ['accountManagment', 'accountOperator', 'operations'],
      module: ['account']
    },
    loadChildren: () => import('./account/account-transfer/account-transfer.module').then(m => m.AccountTransferModule)
  },
  {
    path: 'daily-balance',
    canActivate: [AuthGuard, ModuleGuard, MobileGuard],
    data: {
      role: ['balanceManagment'],
      module: ['account']
    },
    loadChildren: () => import('./daily-balance/daily-balance.module').then(m => m.DailyBalanceModule)
  },
  {
    path: 'accounts-company-managment',
    canActivate: [AuthGuard, ModuleGuard],
    data: {
      role: ['accountCompanyManagment', 'accountCompanyOperator'],
      module: ['account']
    },
    loadChildren: () => import('./account/account-company-managment/account-company-managment.module').then(m => m.AccountCompanyManagmentModule)
  },
  {
    path: 'accounts-company',
    canActivate: [AuthGuard, ModuleGuard],
    data: {
      role: ['accountCompanyManagment'],
      module: ['account']
    },
    loadChildren: () => import('./account/account-company/account-company.module').then(m => m.AccountCompanyModule)
  },
  {
    path: 'accounts',
    canActivate: [AuthGuard, ModuleGuard],
    data: {
      role: ['accountManagment'],
      module: ['account']
    },
    loadChildren: () => import('./account/crud-account/crud-account.module').then(m => m.CrudAccountModule)
  },
  {
    path: 'expenses',
    canActivate: [AuthGuard, ModuleGuard],
    data: {
      role: ['accountCompanyManagment', 'accountCompanyOperator'],
      module: ['account']
    },
    loadChildren: () => import('./expenses/expenses/expenses.module').then(m => m.ExpensesModule)
  },
  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
